export enum HUBSPOT_ENDPOINTS {
  FORMS = "https://api.hsforms.com/submissions/v3/integration/submit/"
}

export enum BASE_ENDPOINTS {
  crispy = "https://crispy.perpetua.io"
}

export enum ACCOUNT_ENDPOINTS {
  signUp = "/account/v2/auth/signup_v2/",
  magicLogin = "/account/v2/magic_link/login/",
  magicLink = "/account/v2/auth/magiclink/email/",
  emailInvite = "/account/v2/auth/email/invites/"
}

export const isChinaApp = () => process.env.APP_LOCALE === "china";

export const isProduction = () => {
  const host = window.location.hostname;
  const environment = process.env.RELEASE_ENV;
  return (
    environment === "production" ||
    // todo remove these, "we are just scared to remove them"
    host === "perpetua.io" ||
    host === "app.perpetua.io"
  );
};

import axios from "axios";
import { AD_SPENDS } from "../../constants/data/adSpend";
import {
  FIRST_STEP_KEYS,
  SECOND_STEP_KEYS
} from "../../constants/data/hubspot";
import { HUBSPOT_ENDPOINTS } from "../../constants/endpoints";

type HubspotFormField = {
  name: string;
  value: string;
};

// maybe instead of sending entire adspend object just pass the title
export const sendDataToHubspot = ({ portalId, formId, data, appendValue }) => {
  if (!data) return;
  // why do we parse? what else can the data be besides an object?
  data = typeof data === "object" ? data : JSON.parse(data);

  const { adSpend } = data || {};

  if (adSpend) {
    // maybe do immutable update
    // this gets the adSpend tier
    adSpend.title = AD_SPENDS.findIndex(
      adSpend => adSpend.title === data.adSpend.title
    );
  }

  const fields: HubspotFormField[] = [];

  const queryParams = new URLSearchParams(window.location.search);
  const hasEmailParam = queryParams.has("email_token");

  const formKeys = hasEmailParam ? SECOND_STEP_KEYS : FIRST_STEP_KEYS;

  for (const [key, hubspotKey] of Object.entries(formKeys)) {
    fields.push({
      name: hubspotKey,
      value: data[key] ? (data[key].title ? data[key].title : data[key]) : ""
    });
  }

  fields.push({
    name: "email",
    value: hasEmailParam ? queryParams.get("email_token") : appendValue
  });

  return axios.post(HUBSPOT_ENDPOINTS.FORMS + `${portalId}/${formId}`, {
    fields,
    submittedAt: +new Date()
  });
};

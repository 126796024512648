type AdSpend = {
  title: string;
};

export const AD_SPENDS: AdSpend[] = [
  { title: "Not Currently Spending" },
  { title: "Less Than $1,000" },
  { title: "Less Than $5,000" },
  { title: "Less Than $10,000" },
  { title: "Less Than $50,000" },
  { title: "Less Than $100,000" },
  { title: "Less Than $200,000" },
  { title: "More Than $200,000" }
];

import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import React from "react";
import { isInternalToGatsby, withUTM } from "../../utils/navigation";

/**
 * Feb 16th 2020 - ported over from https://github.com/perpetua1/perpetua-blog/blob/f0794e000cb13cbbf121e43f2ba6710e6ff408b6/src/components/Link/index.js
 * and converted to typescript
 *
 * Taken from https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links
 *
 * To be used for redirecting to different pages
 * This will determine whether to use a Gatsby <Link> component or the default <a> based on where we're navigating to
 * Use <Link> when navigating to internal (internal to gatsby) pages
 * <a> otherwise
 *
 * @param to If internal of the form: "/blog"
 * @param to If external of the form: "https://www.fijiwater.com/"
 * href is usable as well - but to is checked first
 */

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

interface LinkProps extends Omit<GatsbyLinkProps<{}>, "ref"> {
  href?: string;
}
const Link: React.FC<LinkProps> = props => {
  const {
    children,
    to,
    id,
    activeClassName,
    className,
    partiallyActive,
    href,
    rel,
    onClick,
    onMouseEnter,
    onMouseOut,
    onMouseDown,
    onMouseUp,
    ...other
  } = props;
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.

  const navURL = to ? to : href ? href : "";
  const internal = isInternalToGatsby(navURL);
  const URLWithParams = withUTM(navURL);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    if (rel != undefined && rel != "") {
      return (
        <GatsbyLink
          to={URLWithParams}
          id={id}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          className={className}
          rel={rel}
          onMouseEnter={onMouseEnter}
          onMouseOut={onMouseOut}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onClick={onClick}
          {...other}
        >
          {children}
        </GatsbyLink>
      );
    } else {
      return (
        <GatsbyLink
          to={URLWithParams}
          id={id}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          className={className}
          onMouseEnter={onMouseEnter}
          onMouseOut={onMouseOut}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onClick={onClick}
          {...other}
        >
          {children}
        </GatsbyLink>
      );
    }
  }

  if (rel != undefined && rel != "") {
    return (
      <a
        href={URLWithParams}
        rel={rel}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onClick={onClick}
        className={className}
        id={id}
      >
        {children}
      </a>
    );
  } else {
    return (
      <a
        href={URLWithParams}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onClick={onClick}
        className={className}
        id={id}
      >
        {children}
      </a>
    );
  }
};
export default Link;

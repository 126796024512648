import classNames from "classnames";
import React from "react";

type Input = React.InputHTMLAttributes<HTMLInputElement>;

type CheckboxProps = Omit<Input, "value"> & {
  className?: string;
};

const Checkbox: React.FC<CheckboxProps> = React.forwardRef<
  HTMLInputElement,
  CheckboxProps
>(({ className, ...rest }, ref) => (
  <input
    className={classNames(
      "rounded-full text-indigo-1 focus:ring-0 h-[17px] w-[17px] checked:ease-in",
      className
    )}
    type="checkbox"
    ref={ref}
    {...rest}
  />
));

export default Checkbox;

import {
  UIButton,
  UIButtonSize,
  UIButtonVariant,
  UIColor,
  UIInput,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";
import classNames from "classnames";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";

import { URL } from "../../../constants/destinations";
import { sendMagicLink } from "../../../services/auth/requests";
import { EMAIL_REGEX } from "../../../utils/email";
import Link from "../../Link";

interface EmailFormProps {
  showInvites: boolean;
  showEmailConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailForm: React.FC<EmailFormProps> = ({
  showInvites,
  showEmailConfirmation
}) => {
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<{ email: string; requireAdSpend: boolean }>({ mode: "onChange" });

  const sendMagicLinkRequest = useCallback(async () => {
    try {
      const res = await sendMagicLink({
        referralCode: "",
        ...getValues()
      });
      showEmailConfirmation(true);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }, [getValues("email")]);

  return (
    <form onSubmit={handleSubmit(sendMagicLinkRequest)}>
      <div className="w-full pt-[20px]">
        <UIInput
          className={classNames({
            "rounded-lg border-red-1 hover:border-red-2-dark hover:focus-within:border-red-1 focus-within:border-red-1 ring-red-3":
              errors.email
          })}
          {...register("email", {
            required: true,
            pattern: EMAIL_REGEX
          })}
          placeholder="example@email.com"
          inputLabel="Your Email"
        />
        {errors.email && (
          <UIText className="mt-2" typography={UITypography.caption}>
            Please input a valid email address.
          </UIText>
        )}
      </div>
      <div className="my-4 flex flex-col items-center">
        <span className="mb-4 flex w-full items-center">
          <input
            {...register("requireAdSpend")}
            className="rounded-full text-indigo-1 focus:ring-0 h-[17px] w-[17px] checked:ease-in"
            type="checkbox"
            name="requireAdSpend"
          />
          <UIText
            typography={UITypography.caption}
            color={UIColor.gray1}
            className="ml-2"
          >
            I will be joining an existing Perpetua account.
          </UIText>
        </span>
        <UIButton
          id="signup-submit"
          className="sign-up-conversion"
          variant={UIButtonVariant.primary}
          size={UIButtonSize.default}
          text="Submit"
          disabled={!isValid}
          type="submit"
          //   onClick={() => this.sendMagicLink()}
          block
          //   loading={sendingRequest}
        />
        {!showInvites && (
          <div>
            <UIText
              typography={UITypography.caption}
              color={UIColor.gray1}
              className="mt-5"
            >
              Already have an account?
              <Link
                to={URL.Login}
                className="ml-1 text-indigo-1 hover:text-purple-2-dark"
                rel="noreferrer"
              >
                Sign in
              </Link>
            </UIText>
          </div>
        )}
      </div>
    </form>
  );
};

export default EmailForm;

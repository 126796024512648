import React from "react";
import cn from "classnames";

import { isChinaApp } from "../../../utils/environment";
import { URL } from "../../../constants/destinations";

interface SignUpFooterProps {
  className?: string;
}

const SignUpFooter: React.FC<SignUpFooterProps> = ({ className }) => {
  return (
    <>
      <div
        className={cn(
          "bottom-0 flex w-full justify-center space-x-2 text-gray-2-light text-base",
          className
        )}
      >{/* TODO localize this into all available languages, add language switcher */}
        <p>© 2023 Perpetua an Ascential company</p>

        <a target="_blank" rel="noreferrer" href={URL.Privacy}>
          Privacy Policy
        </a>
        <a target="_blank" rel="noreferrer" href={URL.Tos}>
          Terms of Service
        </a>
      </div>
      {isChinaApp() && (
        <p className="m-auto text-gray-2-light">ICP no: 沪ICP备19018360号-2.</p>
      )}
    </>
  );
};

export default SignUpFooter;

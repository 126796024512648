import React from "react";
import {
  UIText,
  UITypography,
  UIButton,
  UIButtonVariant
} from "@perpetua1/oasis-react";

import Icon, { IconType } from "../../Icon";

interface WelcomeProps {
  onClick: () => void;
}

const Welcome: React.FC<WelcomeProps> = ({ onClick }) => {
  return (
    <div className="flex h-screen">
      <div className="m-auto my-11 flex w-[310px] flex-col items-center space-y-8 text-center md:w-[464px]">
        <Icon iconType={IconType.perpetua} />
        <Icon iconType={IconType.growth} />
        <UIText typography={UITypography.header3}>Welcome to Perpetua</UIText>
        <UIText typography={UITypography.body2}>
          Now that your email has been confirmed, we need a bit more information
          from you to create your account.
        </UIText>
        <UIButton
          variant={UIButtonVariant.primary}
          text="Let's Get Started"
          onClick={() => onClick()}
        />
      </div>
    </div>
  );
};

export default Welcome;

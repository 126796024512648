import { UIContainer } from "@perpetua1/oasis-react";
import { PageProps } from "gatsby";
import React, { useEffect, useState } from "react";
import { magicLogin as magicLoginRequest } from "../services/auth/requests";

import SignUp from "../components/SignUp";
import AccountForm from "../components/SignUp/AccountForm";
import { PlatformRoute, URL } from "../constants/destinations";
import { nav } from "../utils/navigation";

const SignUpPage = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search);
  const email = params.get("email") || "";
  const loginToken = params.get("login_token") || "";
  const emailToken = params.get("email_token") || "";
  const requireAdSpend =
    params.get("require_ad_spend") === "true" ? true : false || false;

  const [error, setError] = useState("");

  // If the user has a login token upon arriving on the sign up page, attempt to log them in
  useEffect(() => {
    const magicLogin = async ({ email, loginToken, requireAdSpend }) => {
      try {
        await magicLoginRequest({ email, loginToken, requireAdSpend });
        nav(`${URL.APP}${PlatformRoute.amazon}/dashboard/`);
      } catch (err) {
        setError("An unexpected error has occured");
      }
    };

    if (loginToken) {
      magicLogin({ email, loginToken, requireAdSpend });
    }
  }, []);

  // If they have an email token then show the account form
  if (emailToken) {
    return (
      <UIContainer className="bg-gray-5">
        <AccountForm />
      </UIContainer>
    );
  }

  // Default render the email form
  return (
    <UIContainer className="bg-gray-5">
      <SignUp location={location} />
    </UIContainer>
  );
};

export default SignUpPage;

import { useMemo } from "react";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import Locale from "react-phone-number-input/locale/en.json";
import countryNamesEN from "react-phone-number-input/locale/en.json";

export interface Country {
  title: string;
  countryCode: string;
  countryCallingCode: string;
}

interface UseCountryProps {
  countryNamesLocale: Locale;
  locale?: string;
  sort?: boolean;
  sortKey?: keyof Country;
}

/**
 *
 * Hook used to map countries for input field purposes for forms
 *
 */
export const useCountries = ({
  countryNamesLocale = countryNamesEN,
  locale = "en",
  sort = true,
  sortKey = "title"
}: UseCountryProps) => {
  const countries = useMemo(() => getCountries(), []);

  const mappedCountries: Country[] = useMemo(
    () =>
      countries.map(country => ({
        title: countryNamesLocale[country],
        countryCode: country,
        countryCallingCode: `+${getCountryCallingCode(country)}`
      })),
    [countryNamesLocale]
  );

  if (!sort) {
    return mappedCountries;
  }

  return mappedCountries.sort((a, b) =>
    a[sortKey].localeCompare(b[sortKey], locale, { sensitivity: "base" })
  );
};

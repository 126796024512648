import {
  UIColor,
  UILoadingIndicator,
  UIPill,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";
import axios from "axios";
import { PageProps } from "gatsby";
import React, { useEffect, useState } from "react";
import Icon, { IconType } from "../../components/Icon";
import { ACCOUNT_ENDPOINTS, BASE_ENDPOINTS } from "../../constants/endpoints";
import Link from "../Link";
import EmailConfirmation from "./EmailConfirmation";
import EmailForm from "./EmailForm";
import SignUpFooter from "./SignUpFooter";
import SignUpInstructions from "./SignUpInstructions";

interface SignUpProps {
  location: PageProps["location"];
}

const SignUp: React.FC<SignUpProps> = ({ location }) => {
  const [invites, setInvites] = useState([]);
  const [showInvites, setShowInvites] = useState(false);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [error, setError] = useState("");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [requireAdSpend, setRequireAdspend] = useState(true);
  const [email, setEmail] = useState("");
  const [expired, setExpired] = useState(false);
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);

  const params = new URLSearchParams(location.search);
  const emailToken = params.get("email_token") || "";

  useEffect(() => {
    const fetchPendingInvites = async (emailToken: string) => {
      try {
        const { data, status } = await axios.get(
          BASE_ENDPOINTS.crispy +
            ACCOUNT_ENDPOINTS.emailInvite +
            "?" +
            new URLSearchParams({ email_token: emailToken })
        );
        if (status === 200) {
          setInvites(data.results);
          setShowInvites(data.results.length !== 0);
          setIsInitialLoad(false);
        } else {
          if (data.data.email_token) {
            setInvites([]);
            setIsInitialLoad(false);
            setIsTokenInvalid(true);
            setError("Token is expired or invalid.");
          } else {
            // In the event that the error isn't that the token is expired
            setError(
              data.detail || data.message || "An unexpected error has occurred"
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    setIsInitialLoad(false);

    if (emailToken) {
      fetchPendingInvites(emailToken);
    }
  }, []);

  const renderHeader = () => {
    // const { params } = this.props.match;

    // States for account sign up
    if (isInitialLoad) {
      return <UILoadingIndicator />;
    }

    // // displays if user is invited to join
    if (showInvites) {
      const companyNames = invites.map(company => (
        <UIPill
          key={company}
          text={company.name}
          textColor={UIColor.indigoDark2}
          containerClassName="text-sm bg-indigo-3-light m-1"
        />
      ));

      const title =
        invites.length === 1
          ? "You've been invited to manage 1 account on Perpetua"
          : `You've been invited to manage ${invites.length} accounts on Perpetua`;

      return (
        <div>
          <p className="mb-2 text-center text-3xl font-semibold">{title}</p>
          <div className="flex flex-wrap justify-center py-6">
            {companyNames}
          </div>
          <p className="text-center text-gray-1">
            Please verify your emaill address to continue.
          </p>
        </div>
      );
    }

    return (
      <div className="text-center">
        <UIText typography={UITypography.header2} className="mb-2">
          {"Let's Get Started"}
        </UIText>
        <UIText color={UIColor.gray1}>
          Please verify your email address to create your Perpetua account.
        </UIText>
      </div>
    );
  };

  return (
    <div className="md:h-screen">
      <div className="flex min-h-full flex-col md:p-8">
        <div className="flex flex-grow flex-col justify-center">
          <Link to="/" className="mx-auto mb-10 mt-20">
            <Icon iconType={IconType.perpetua} />
          </Link>
          <div className="my-[40px] mx-6 flex flex-col-reverse place-items-center justify-center md:mx-[52px] lg:my-0 lg:flex-row lg:space-x-6">
            <SignUpInstructions requireAdSpend />
            <div className="mb-16 max-w-[386px] rounded-lg bg-white p-10 shadow-lg lg:m-10">
              {showEmailConfirmation ? (
                <EmailConfirmation
                  email={email}
                  confirmEmail={setShowEmailConfirmation}
                />
              ) : (
                <>
                  <div>
                    {isTokenInvalid && (
                      <div className="pb-3 text-center text-lg text-red-1">
                        {/* {Strings.inviteSignup_tokenInvalid()} */}
                        SIGNUP_TOKEN_INVALID
                      </div>
                    )}
                    {renderHeader()}
                  </div>
                  <EmailForm
                    showInvites={showInvites}
                    showEmailConfirmation={setShowEmailConfirmation}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <SignUpFooter />
      </div>
    </div>
  );
};

export default SignUp;

import React, { useState } from "react";

import Welcome from "../Welcome";
import AccountInfoForm from "../AccountInfoForm";

const AccountForm = () => {
  const params = new URLSearchParams(location.search);
  const email = params.get("email") || "";
  const emailToken = params.get("email_token") || "";
  const hasReferralCode = params.has("referral_code");

  const [showWelcome, setShowWelcome] = useState(true);

  const toggleWelcome = () => setShowWelcome(s => !s);

  return (
    <>
      {showWelcome ? (
        <Welcome onClick={toggleWelcome} />
      ) : (
        <AccountInfoForm
          emailToken={emailToken}
          email={email}
          hideReferral={hasReferralCode}
        />
      )}
    </>
  );
};

export default AccountForm;

import {
  UIButton,
  UIButtonSize,
  UIButtonVariant,
  UIColor,
  UIDropdown,
  UIInput,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";
import classNames from "classnames";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import countryNamesEN from "react-phone-number-input/locale/en.json";
import { useMedia } from "use-media";

import { AD_SPENDS } from "../../../constants/data/adSpend";
import { Hubspot, URL } from "../../../constants/destinations";
import { LOCAL_STORAGE } from "../../../constants/storage";
import { Country, useCountries } from "../../../hooks/useCountries";
import { signUp } from "../../../services/auth/requests";
import { sendDataToHubspot } from "../../../services/hubspot/requests";
import { useSegment } from "../../../utils/analytics";
import Checkbox from "../../Checkbox";
import Icon, { IconType } from "../../Icon";
import Link from "../../Link";
import SignUpInstructions from "../SignUpInstructions";

type FormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  country: string;
  phoneNumber: string;
  password: string;
  referralCode: string;
  adSpend: string;
  tos: boolean;
  newsletter: boolean;
};

type FormValue = keyof FormValues;

interface SignUpFormProps {
  emailToken: string;
  email: string;
  hideReferral: boolean;
}

const AccountInfoForm: React.FC<SignUpFormProps> = ({
  emailToken,
  email,
  hideReferral
}) => {
  const isMobile = useMedia({ maxWidth: "640px" });
  const {} = useSegment();
  // const { params } = useQueryParams(["referral_code", "require_ad_spend"]);
  // const { referral_code: referralCode, require_ad_spend: requireAdSpend } =
  //   params;
  const queryParams = new URLSearchParams(window.location.search);
  const referralCode = queryParams.get("referral_code") || "";
  const requireAdSpend =
    queryParams.get("require_ad_spend")?.toLocaleLowerCase() === "true"
      ? true
      : false || false;

  const countries = useCountries({ countryNamesLocale: countryNamesEN });
  const [selectedCountry, setSelectedCountry] = useState<Country>();

  const [selectedAdSpend, setSelectedAdSpend] =
    useState<typeof AD_SPENDS[number]>();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { isValid, isDirty, errors }
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      referralCode: referralCode,
      tos: false,
      newsletter: false
    }
  });

  const isFormFieldMissing = (formField: FormValue) =>
    errors[formField]?.type === "required";

  const formHandler: SubmitHandler<FormValues> = async ({
    firstName,
    lastName,
    companyName,
    password,
    phoneNumber,
    newsletter,
    referralCode
  }) => {
    try {
      const { status, data } = await signUp({
        firstName,
        lastName,
        companyName,
        password,
        referralCode,
        emailToken,
        phoneNumber,
        wantsNewsletter: newsletter
      });

      if (status === 201 && data.key) {
        localStorage.setItem(LOCAL_STORAGE.USER_KEY, data.key);
        await sendDataToHubspot({
          portalId: Hubspot.portalId,
          formId: requireAdSpend
            ? Hubspot.newAccountFormId
            : Hubspot.existingAccountFormId,
          data: {
            email,
            firstName,
            lastName,
            companyName,
            phoneNumber,
            referralCode,
            currentCountry: selectedCountry?.countryCode,
            ...(requireAdSpend && { adSpend: selectedAdSpend })
          },
          appendValue: email
        });
      }
    } catch (e) {
      console.log({ error: e });
    }
  };

  return (
    <>
      <Icon
        iconType={IconType.backgroundCircles}
        className="absolute bottom-0 w-52 right-[-62px] sm:right-[-48px]"
      />
      <div className="mb-12 mx-6 h-auto place-items-center justify-center sm:mt-0 md:mx-[52px] lg:my-0 lg:flex lg:h-screen lg:space-x-6">
        <div className="relative flex flex-col items-center justify-between mb-[64px] sm:items-center lg:max-w-md lg:items-start">
          <Link to="/" className="mx-auto mb-16 mt-8 lg:hidden">
            <Icon iconType={IconType.perpetua} />
          </Link>
          <p className="pb-6 text-4xl font-semibold">
            Create
            <span className="ml-2 bg-gradient-to-br from-blue-1 to-purple-1 bg-clip-text text-transparent">
              {requireAdSpend ? "your account" : "your profile"}
            </span>
          </p>
          <div className="space-y-4 text-center text-lg lg:text-left">
            <UIText typography={UITypography.body2} color={UIColor.grayDark3}>
              Join thousands of businesses that use Perpetua's eCommerce
              advertising optimization and intelligence software to profitably
              scale growth on Amazon, Instacart and other marketplaces.
            </UIText>
            <SignUpInstructions requireAdSpend={requireAdSpend} />
          </div>
        </div>
        <form
          onSubmit={handleSubmit(formHandler)}
          className="relative grid sm:grid-cols-2 gap-x-5 rounded-lg bg-white p-8 sm:py-12 sm:px-18 shadow-lg lg:min-w-[791px] z-0"
        >
          <div className="w-full py-3">
            <UIInput
              className={classNames({
                "rounded-lg border-red-1 hover:border-red-2-dark hover:focus-within:border-red-1 focus-within:border-red-1 ring-red-3":
                  errors.firstName
              })}
              placeholder="Jane"
              inputLabel="First Name"
              {...register("firstName", { required: true })}
            />
            {isFormFieldMissing("firstName") && (
              <UIText color={UIColor.red1} className="mt-2 ml-1">
                You must enter a first name.
              </UIText>
            )}
          </div>
          <div className="w-full py-3">
            <UIInput
              className={classNames({
                "rounded-lg border-red-1 hover:border-red-2-dark hover:focus-within:border-red-1 focus-within:border-red-1 ring-red-3":
                  errors.lastName
              })}
              placeholder="Doe"
              inputLabel="Last Name"
              {...register("lastName", { required: true })}
            />
            {isFormFieldMissing("lastName") && (
              <UIText color={UIColor.red1} className="mt-2 ml-1">
                You must enter a last name.
              </UIText>
            )}
          </div>
          <div className="w-full py-3">
            <UIInput
              className={classNames({
                "rounded-lg border-red-1 hover:border-red-2-dark hover:focus-within:border-red-1 focus-within:border-red-1 ring-red-3":
                  errors.companyName
              })}
              placeholder="My Company"
              inputLabel="Company Name"
              {...register("companyName", { required: true })}
            />
            {isFormFieldMissing("companyName") && (
              <UIText color={UIColor.red1} className="mt-2 ml-1">
                You must enter a company name.
              </UIText>
            )}
          </div>
          <div className="w-full py-3">
            <UIText typography={UITypography.label2}>Country</UIText>
            <UIDropdown
              block
              dynamicPopoutSize={true}
              placeholder="Select"
              searchable={true}
              items={countries}
              selectedItem={selectedCountry}
              onClick={idx => {
                const country = countries[idx];
                setValue("country", country.title);
                setSelectedCountry(country);
              }}
            />
          </div>
          <div className="w-full py-3">
            <UIInput
              className={classNames({
                "rounded-lg border-red-1 hover:border-red-2-dark hover:focus-within:border-red-1 focus-within:border-red-1 ring-red-3":
                  errors.phoneNumber
              })}
              placeholder="+0 000 000 0000"
              inputLabel="Phone Number"
              {...register("phoneNumber", { required: true })}
            />
            {isFormFieldMissing("phoneNumber") && (
              <UIText color={UIColor.red1} className="mt-2 ml-1">
                You must enter a phone number.
              </UIText>
            )}
          </div>
          {!referralCode && (
            <div className="w-full py-3">
              <UIInput
                placeholder="Enter offer code (optional)"
                inputLabel="Offer or Referral Code"
                {...register("referralCode")}
              />
            </div>
          )}
          {requireAdSpend && (
            <div className="w-full py-3">
              <UIText typography={UITypography.label2}>
                Average Monthly Ad Spend
              </UIText>
              <UIDropdown
                placeholder="Select Ad Spend"
                block
                items={AD_SPENDS}
                selectedItem={selectedAdSpend}
                onClick={index => {
                  const adSpend = AD_SPENDS[index];
                  setValue("adSpend", adSpend.title);
                  setSelectedAdSpend(adSpend);
                }}
              />
            </div>
          )}
          <div className="w-full py-3">
            <UIInput
              placeholder="Create a password"
              inputLabel="Password"
              tooltipDisabled={false}
              tooltipText="Must be at least 10 characters and non-numeric"
              {...register("password", {
                required: true,
                minLength: 10,
                pattern: /\+?\D+/
              })}
              //   externalValidators={externalValidators}
            />
            {isFormFieldMissing("password") && (
              <UIText color={UIColor.red1} className="mt-2 ml-1">
                You must enter a password.
              </UIText>
            )}
            {(errors.password?.type === "minLength" ||
              errors.password?.type === "pattern") && (
              <UIText color={UIColor.red1} className="mt-2 ml-1">
                Password must be at least 10 characters and non-numeric.
              </UIText>
            )}
          </div>
          <div className="w-full col-span-full mt-3">
            <span className="flex items-center mb-1">
              <Controller
                name="tos"
                rules={{ required: true }}
                control={control}
                render={({ field }) => <Checkbox {...field} />}
              />
              <UIText className="ml-2">
                I agree to the
                <a
                  href={URL.Tos}
                  className="hover:text-purple-2-dark text-indigo-1"
                  target="_blank"
                  rel="noreferrer"
                >{` Terms of Service `}</a>
                and{" "}
                <a
                  href={URL.Privacy}
                  target="_blank"
                  className="hover:text-purple-2-dark text-indigo-1"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </UIText>
            </span>

            <span className="flex items-center">
              <Controller
                name="newsletter"
                control={control}
                render={({ field }) => <Checkbox {...field} />}
              />
              <UIText className="ml-2">
                {/* {Strings.onboarding_checkbox_marketing()} */}
                NEWSLETTER_TEXT
              </UIText>
            </span>
            <span className="mt-6 flex flex-col items-center md:flex-row lg:flex-row">
              <UIButton
                className="lg:mb-0 mr-6 mb-8 md:mb-0"
                block={isMobile ? true : false}
                variant={UIButtonVariant.primary}
                size={UIButtonSize.default}
                type="submit"
                text="Create Account"
                disabled={!isValid || !isDirty}
                // loading={sendingRequest}
              />
              <UIText typography={UITypography.caption} color={UIColor.gray1}>
                {/* {Strings.alreadyHaveAnAccount()} */}
                ALREADY_HAVE_ACCOUNT
                <a
                  className="ml-1 text-indigo-1 hover:text-purple-2-dark"
                  href={URL.Login}
                  rel="noreferrer"
                >
                  {/* {Strings.signIn()} */}
                  SIGN_IN
                </a>
              </UIText>
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default AccountInfoForm;

import React from "react";
import { UIText, UITypography, UIColor } from "@perpetua1/oasis-react";

interface SignUpInstructionsProps {
  requireAdSpend?: boolean;
}

const SignUpInstructions: React.FC<SignUpInstructionsProps> = ({
  requireAdSpend = false
}) => {
  return (
    <div className="flex flex-col items-center justify-between lg:max-w-md lg:items-start">
      <div className="w-full space-y-4 text-center text-lg lg:text-left">
        <UIText typography={UITypography.body2} color={UIColor.grayDark3}>
          {"Just a few steps and you’ll be ready to explore Perpetua."}
        </UIText>
        <div className="space-y-4 sm:max-md:space-y-0 sm:flex sm:justify-around gap-6 lg:block mt-0 pt-8 text-lg font-semibold text-gray-1">
          <div className="flex flex-col items-center lg:flex-row">
            <div className="mb-6 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-white text-center shadow-xl lg:mb-0 lg:mr-6">
              1
            </div>
            <UIText
              typography={UITypography.body2}
              color={UIColor.gray1}
              className="sm:max-md:w-min sm:max-md:text-center font-normal"
            >
              {requireAdSpend
                ? "Confirm your company information "
                : "Confirm your personal information"}
            </UIText>
          </div>
          <div className="flex flex-col items-center lg:flex-row">
            <div className="mb-6 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-white text-center shadow-xl lg:mb-0 lg:mr-6">
              2
            </div>
            <UIText
              typography={UITypography.body2}
              color={UIColor.gray1}
              className="sm:max-md:w-min sm:max-md:text-center font-normal"
            >
              {requireAdSpend
                ? "Connect your marketplace account"
                : "Request an invite from the owner of your company’s account"}
            </UIText>
          </div>
          <div className="flex flex-col items-center lg:flex-row">
            <div className="mb-6 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-white text-center shadow-xl lg:mb-0 lg:mr-6">
              3
            </div>
            <UIText
              typography={UITypography.body2}
              color={UIColor.gray1}
              className="sm:max-md:w-min sm:max-md:text-center font-normal"
            >
              {requireAdSpend
                ? "A sales representative will be in touch set up a product demo and get you started with Perpetua"
                : "Ensure your account owner completes the invite teammates action from within the account settings menu"}
            </UIText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpInstructions;

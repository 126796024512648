export const FIRST_STEP_KEYS = {
  acquisition_channel_source: "acquisition_channel_source",
  google_analytics_client_id: "google_analytics_client_id",
  gclid: "gclid",
  fbclid: "fbclid",
  li_fat_id: "li_fat_id",
  partner_key: "partner_key",
  utm_campaign: "utm_campaign",
  utm_content: "utm_content",
  utm_medium: "utm_medium",
  utm_source: "utm_source",
  utm_term: "utm_term"
};

export const SECOND_STEP_KEYS = {
  firstName: "firstname",
  lastName: "lastname",
  companyName: "company",
  currentCountry: "where_are_you_located_country_code_en",
  phoneNumber: "phone",
  adSpend: "perpetua_ad_spend_tier",
  referralCode: "referrer_code__c"
};

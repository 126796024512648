import axios from "axios";
import { ACCOUNT_ENDPOINTS, BASE_ENDPOINTS } from "../../constants/endpoints";
import { LOCAL_STORAGE } from "../../constants/storage";

/**
 * Used to create an account in the funnel
 * @param {{firstName: string, lastName: string, companyName: string, emailToken: string, password: string, phoneNumber: string, wantsNewsletter: boolean,referralCode: string}}
 * @returns Promise
 */
export const signUp = ({
  firstName,
  lastName,
  companyName,
  emailToken,
  password,
  phoneNumber,
  wantsNewsletter,
  referralCode
}: {
  firstName: string;
  lastName: string;
  companyName: string;
  emailToken: string;
  password: string;
  phoneNumber: string;
  wantsNewsletter: boolean;
  referralCode: string;
}) =>
  axios.post(BASE_ENDPOINTS.crispy + ACCOUNT_ENDPOINTS.signUp, {
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
    email_token: emailToken,
    password,
    phone_number: phoneNumber,
    accepted_tos: true,
    accepted_marketing: wantsNewsletter,
    referral_code: referralCode
  });

/**
 * Used to login user during signup if they have a login token
 * @param email string - the email used for signing up
 * @param login_token string - the login token
 * @param require_ad_spend boolean - if user is signing up to an existing Perpetua account
 * @returns a Promise with success/failure of magic login
 */
export const magicLogin = ({ email, loginToken, requireAdSpend = false }) => {
  // setItem(cacheKeys.token, login_token);
  localStorage.setItem(LOCAL_STORAGE.USER_KEY, loginToken);
  return axios.post(BASE_ENDPOINTS.crispy + ACCOUNT_ENDPOINTS.magicLogin, {
    email,
    login_token: loginToken,
    require_ad_spend: requireAdSpend
  });
};

/**
 * Send user a magic link to sign up to their email
 * @param email string - the email to send magic link to
 * @param referral_code string - referral code
 * @param trackSignup boolean - flag to determine tracking sign up event
 * @param requireAdSpend boolean - if user is signing up using existing Perpetua account
 * @returns
 */
export const sendMagicLink = ({
  email,
  referralCode,
  trackSignup = true,
  requireAdSpend = false
}) => {
  const windowBaseURI = `${window.location.protocol}//${window.location.host}/signup/`;

  return axios.post(BASE_ENDPOINTS.crispy + ACCOUNT_ENDPOINTS.magicLink, {
    email,
    referral_code: referralCode,
    email_link_uri: windowBaseURI,
    require_ad_spend: requireAdSpend
  });
};

import {
  UIButton,
  UIButtonSize,
  UIButtonVariant,
  UIColor,
  UIIcon,
  UIIconType,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";
import React from "react";

import { URL } from "../../../constants/destinations";
import Link from "../../Link";

interface EmailConfirmationProps {
  confirmEmail: (isConfirmed: boolean) => void;
  email: string;
}

const EmailConfirmation: React.FC<EmailConfirmationProps> = ({
  email,
  confirmEmail
}) => {
  return (
    // signup-center-text
    <div className="space-y-4 text-center">
      <div className="mb-4 inline-flex rounded-full bg-blue-3-light p-6">
        <UIIcon
          iconType={UIIconType.email}
          iconColor={UIColor.gray2}
          iconSize={"w-6 h-6"}
          className="[&>svg]:fill-gray-2"
        />
      </div>
      <UIText typography={UITypography.header2}>
        {/* {Strings.onboarding_emailConfirmation_title()} */}
        EMAIL_CONFIRMATION_TITLE
      </UIText>
      <UIText className="mb-2">
        We've sent you an email to confirm your email address, simply tap the
        'Confirm' button in the email sent to
        <p className="inline font-semibold"> {email}</p>
      </UIText>
      <UIText className="mb-2">or</UIText>
      <UIButton
        variant={UIButtonVariant.secondary}
        size={UIButtonSize.large}
        text={"Try a Different Email"}
        block
        onClick={() => confirmEmail(false)}
      />
      <UIText typography={UITypography.caption} color={UIColor.gray1}>
        {/* {Strings.alreadyHaveAnAccount()} */}
        Already have an account?
        <Link
          to={URL.Login}
          className="ml-1 text-indigo-1 hover:text-purple-2-dark"
          rel="noreferrer"
        >
          Sign in
        </Link>
      </UIText>
    </div>
  );
};

export default EmailConfirmation;
